<template>
  <toggle-section
    :is-open="isOpen.business"
    section="business"
    chevron-position="right"
    data-cy="business-section"
    @toggle="toggleTable"
  >
    <template #titleSection>
      <lf-h3>{{ $t("COMMON.BUSINESS") }}</lf-h3>
    </template>
    <template #content>
      <div class="space-y-4">
        <div class="border rounded-lg">
          <div class="flex items-center p-5 space-x-2 border-b">
            <icon-base :icon="IconCompanySmallNoBg" class="-mt-1" />
            <lf-h3>
              {{ business.BestSection.CompanyName }}
            </lf-h3>
          </div>
          <div
            class="px-5 py-3 flex flex-row items-center justify-start space-x-2 border-b"
          >
            <div
              class="rounded-full text-xxs font-semibold border px-2 py-0.5 uppercase text-label"
            >
              {{ business.BestSection.YearsInBusiness }}
              {{
                business.BestSection.YearsInBusiness === 1
                  ? $t("DEALS.LEXIS_NEXIS.KYB.YEAR_IN_BUSINESS")
                  : $t("DEALS.LEXIS_NEXIS.KYB.YEARS_IN_BUSINESS")
              }}
            </div>
            <div
              class="rounded-full text-xxs font-semibold px-2 py-0.5 uppercase"
              :class="businessActiveClasses(business.BestSection.IsActive)"
            >
              {{
                business.BestSection.IsActive
                  ? $t("DEALS.LEXIS_NEXIS.KYB.BUSINESS_ACTIVE")
                  : $t("DEALS.LEXIS_NEXIS.KYB.BUSINESS_INACTIVE")
              }}
            </div>
          </div>
          <div class="p-5 flex flex-row space-x-2">
            <icon-base icon="address" class="mt-1" />
            <div class="flex flex-col space-y-0.5">
              <div class="font-semibold text-headline">
                {{ business.BestSection.Address?.County || "-" }}
              </div>
              <div>
                {{ cleanAndFormatAddress(business.BestSection.Address) }}
              </div>
            </div>
            <div class="self-center">
              <update-service-record
                :model="updateAddressModel"
                :service-name="$t('DEALS.LEXIS_NEXIS.SERVICE_NAME')"
                @record:update="updateAddress"
              />
            </div>
          </div>
        </div>
        <toggle-section
          :key="getSectionName('ucc', index)"
          :is-open="isOpen[getSectionName('ucc', index)]"
          :section="getSectionName('ucc', index)"
          chevron-position="right"
          class="rounded-lg"
          @toggle="toggleTable"
        >
          <template #titleSection>
            <lf-h3>{{ $t("COMMON.UCC") }}</lf-h3>
          </template>
          <template #content>
            <div class="grid grid-cols-2 gap-4">
              <div class="grid grid-cols-2 gap-2 pr-4 border-r">
                <template
                  v-for="(ucc, uccIndex) in Object.entries(
                    filteredUccSection
                  ).slice(0, 3)"
                  :key="uccIndex"
                >
                  <div>{{ camelCaseToWords(ucc[0]) }}</div>
                  <div class="text-right text-headline">{{ ucc[1] }}</div>
                </template>
              </div>
              <div class="grid grid-cols-2 gap-2">
                <template
                  v-for="(ucc, uccIndex) in Object.entries(
                    filteredUccSection
                  ).slice(3)"
                  :key="uccIndex"
                >
                  <div>{{ camelCaseToWords(ucc[0]) }}</div>
                  <div class="text-right text-headline">{{ ucc[1] }}</div>
                </template>
              </div>
            </div>
          </template>
        </toggle-section>
        <toggle-section
          :key="getSectionName('lien', index)"
          :is-open="isOpen[getSectionName('lien', index)]"
          :section="getSectionName('lien', index)"
          chevron-position="right"
          :has-padding="false"
          class="rounded-lg"
          @toggle="toggleTable"
        >
          <template #titleSection>
            <lf-h3>{{ $t("COMMON.LIEN") }}</lf-h3>
          </template>
          <template #content>
            <div class="grid grid-cols-3 divide-x divide-gray-200">
              <div
                v-for="(lien, lienIndex) in Object.entries(filteredLienSection)"
                :key="lienIndex"
                class="p-5"
              >
                <div>{{ camelCaseToWords(lien[0]) }}</div>
                <div class="text-headline text-xl font-semibold">
                  {{ lien[1] }}
                </div>
              </div>
            </div>
          </template>
        </toggle-section>
        <toggle-section
          :key="getSectionName('ids', index)"
          :is-open="isOpen[getSectionName('ids', index)]"
          :section="getSectionName('ids', index)"
          chevron-position="right"
          :has-padding="false"
          class="rounded-lg"
          @toggle="toggleTable"
        >
          <template #titleSection>
            <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.BUSINESS_IDS") }}</lf-h3>
          </template>
          <template #content>
            <div class="bg-gray-100 p-5">
              <div class="grid grid-cols-2 gap-4">
                <div class="grid grid-cols-2 grid-rows-4 gap-2 pr-4 border-r">
                  <template
                    v-for="(id, idIndex) in Object.entries(
                      business.BestSection.BusinessIds
                    ).slice(0, 4)"
                    :key="idIndex"
                  >
                    <div>{{ camelCaseToWords(id[0]) }}</div>
                    <div class="text-right text-headline">
                      {{ id[1] }}
                    </div>
                  </template>
                </div>
                <div class="grid grid-cols-2 grid-rows-4 gap-2">
                  <template
                    v-for="(id, idIndex) in Object.entries(
                      business.BestSection.BusinessIds
                    ).slice(4)"
                    :key="idIndex"
                  >
                    <div>{{ camelCaseToWords(id[0]) }}</div>
                    <div
                      class="text-right text-headline flex items-center justify-end"
                    >
                      <update-service-record
                        v-if="id[0] === 'SeleID'"
                        class="-mb-4 -mt-4"
                        placement-class="right-0 top-0 "
                        :model="getSeleIdUpdateRecord(id[1])"
                        :service-name="$t('DEALS.LEXIS_NEXIS.SERVICE_NAME')"
                        @record:update="updateSeleId"
                      />
                      <span>{{ id[1] }}</span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </toggle-section>
      </div>
    </template>
  </toggle-section>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import { computed } from "vue";
import { useDeals } from "@/hooks/deals";
import { useStore } from "vuex";
import pick from "lodash/pick";
import ToggleSection from "@/components/ToggleSection.vue";
import { camelCaseToWords, formatAddress } from "@/helpers/formatting";
import { getSectionName, cleanAndFormatAddress } from "@/helpers/lexisnexis";
import type { IAddress, ServiceUpdateRecord } from "@/models/common";
import type { IBusiness } from "@/models/applications";
import applicationsApiService from "@/services/modules/applications";
import type {
  Business,
  LienSection,
  UCCSection
} from "@/models/commercialData/lexisnexis/KybReport";
import IconCompanySmallNoBg from "@/components/icons/IconCompanySmallNoBg.vue";
import UpdateServiceRecord from "@/components/deals/dataServices/UpdateServiceRecord.vue";

const emit = defineEmits<{
  toggle: [key: string];
}>();

const props = defineProps({
  business: {
    type: Object as PropType<Business>,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  isOpen: {
    type: Object,
    required: true
  }
});

const { activeDeal, cleanBusinessValues } = useDeals();
const { dispatch } = useStore();

const filteredUccSection = computed(() => {
  const wanted = [
    "DerogSummaryCntUCC",
    "TotalAsDebtorCount",
    "SecuredAssetsCntUCC",
    "TotalAsSecuredCount",
    "ReturnedAsDebtorCount",
    "ReturnedAsSecuredCount"
  ];

  const value: Partial<UCCSection> = Object.fromEntries(
    Object.entries(props.business.UCCSection).filter(([key]) =>
      wanted.includes(key)
    )
  );
  return value;
});

const filteredLienSection = computed(() => {
  const wanted = [
    "TotalRecordCount",
    "DerogSummaryCntJL",
    "ReturnedRecordCount"
  ];

  const value: Partial<LienSection> = Object.fromEntries(
    Object.entries(props.business.LienSection ?? {}).filter(([key]) =>
      wanted.includes(key)
    )
  );
  return value;
});

const updateAddressModel = computed<ServiceUpdateRecord>(() => {
  const address = pick(activeDeal.value?.business?.address, [
    "address_line",
    "address_line2",
    "city",
    "state",
    "zip"
  ]);
  return {
    field: "address",
    modelToUpdate: {
      model: "application.business",
      field: "business_address"
    },
    modelValues: {
      oldValue: formatAddress(address),
      newValue: cleanAndFormatAddress(props.business.BestSection.Address)
    }
  };
});

const getSeleIdUpdateRecord = (value: string) => {
  return {
    field: "seleID",
    modelToUpdate: {
      model: "profile.business",
      field: value
    },
    modelValues: {
      oldValue: activeDeal.value?.business?.sele_id?.toString() || "",
      newValue: value.toString()
    }
  };
};

const updateAddress = async () => {
  if (!activeDeal.value) {
    return;
  }
  const address = props.business.BestSection.Address;
  const addressLine = [
    address.StreetNumber || "",
    address.StreetName || "",
    address.StreetSuffix || ""
  ]
    .filter(Boolean)
    .join(" ");
  const zip = [address.Zip5 || "", address.Zip4 || ""]
    .filter(Boolean)
    .join("-");
  const city = address.City || "";
  const state = address.State || "";
  const updated: IAddress = {
    ...activeDeal.value.business?.address,
    address_line: addressLine,
    address_line2: "",
    city,
    state,
    zip,
    country: "US",
    is_primary: activeDeal.value.business.address?.is_primary || false
  };
  const payload: Partial<IBusiness> = cleanBusinessValues({
    ...activeDeal.value.business,
    address: updated
  });
  await applicationsApiService.updateBusinessInfo(payload, activeDeal.value.id);
  await dispatch("applications/find", { appId: activeDeal.value.id });
};

const updateSeleId = async (model: ServiceUpdateRecord) => {
  if (!activeDeal.value) {
    return;
  }
  const payload: Partial<IBusiness> = cleanBusinessValues({
    ...activeDeal.value.business,
    sele_id: model.modelValues.newValue
  });
  await applicationsApiService.updateBusinessInfo(payload, activeDeal.value.id);
  await dispatch("applications/find", { appId: activeDeal.value.id });
};

const toggleTable = (section: string) => {
  emit("toggle", section);
};

const businessActiveClasses = (isActive: boolean) => {
  return {
    "text-green-500 bg-green-200": isActive,
    "text-error bg-error bg-opacity-5": !isActive
  };
};
</script>
